//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
  },
  props: {
    chartOption: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      pageData: {
        pageIndex: 1,
        pageSize: 20,
        totalNum: 0,
      },
    };
  },
  methods: {
    loadingChange(val) {
      this.loading = val;
    },
    opened() {
      this.$nextTick(() => {
        this.$emit(
          "getData",
          this.pageData.pageIndex,
          this.pageData.pageSize,
          this.chartOption.chartType
        );
      });
    },
    /**
     * 查看详情
     */
    handleInfo(val) {
      this.$emit("handleInfo", val.name, this.chartOption.chartType);
    },
    /**
     * 每页xx条
     */
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.pageData.pageIndex = 1;
      this.$emit(
        "getData",
        this.pageData.pageIndex,
        this.pageData.pageSize,
        this.chartOption.chartType
      );
    },
    /**
     * 当前第x页
     */
    handleCurrentChange(val) {
      this.pageData.pageIndex = val;
      this.$emit(
        "getData",
        this.pageData.pageIndex,
        this.pageData.pageSize,
        this.chartOption.chartType
      );
    },
  },
};
